const ENCOREMED_CONSTANTS = {
  TOKEN: process.env.REACT_APP_ENCOREMED_TOKEN,
  // CODE: 'kpj1',
  URL: 'https://openapi-staging.encoremed.io/api/',
  CREATE_APPOINTMENT: '/appointment/create',
  LIST_APPOINTMENT: '/appointment/list',
  VIEW_APPOINTMENT: '/appointment/view',
  VIEW_DOCTOR: '/doctor/view',
  DOCTOR_CALENDAR: '/doctor/availability/calendar',
  DOCTOR_TIMESLOT: '/doctor/availability/timeslot',
};

// revamp url (deprecated on 24/10/2023, this code should not be hardcoded)
// ENCOREMED_CONSTANTS.URL = ENCOREMED_CONSTANTS.URL + ENCOREMED_CONSTANTS.CODE + '/v1';

export default ENCOREMED_CONSTANTS;

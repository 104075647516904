import React, { useState, useEffect, lazy, Suspense } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import ScrollTop from './components/ScrollTop';
import {
    AuthProvider,
    TabProvider,
    LangProvider,
    DoctorFilterProvider,
    HealthPackageProvider,
    CartProvider,
} from './context/AppContext';
import AllContext from './context/AllContext';
import './App.css';
import { CircularProgress } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';

// test
import Test from './pages/Test/test';
import Success from './pages/Payment/Success';
import Fail from './pages/Payment/Fail';

//api
import { api_seo } from './api/page';
import { get_token } from './api/auth';
import { getToken } from './localstorage';
import { api_get_favicon } from './api/hospital';

//lazy load page components
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Home = lazy(() => import('./pages/Home/Home/Home'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));
const OurHospitalKpj = lazy(() => import('./pages/OurHospitalKpj/OurHospitalKpj'));
const FindADoctorsKpj = lazy(() => import('./pages/FindADoctorsKpj/FindADoctorsKpj'));
const OurSpecialityCentreKpj = lazy(() =>
    import('./pages/OurSpecialityCentreKpj/OurSpecialityCentreKpj')
);
const IndividualDoctorKpj = lazy(() =>
    import('./pages/IndividualDoctorKpj/IndividualDoctorKpj')
);
const IndividualHospitalKpj = lazy(() =>
    import('./pages/IndividualHospitalKpj/IndividualHospitalKpj')
);
const IndividualHealthPackageKpj = lazy(() =>
    import('./pages/IndividualHealthPackageKpj')
);
const ProfilePageKpj = lazy(() => import('./pages/ProfilePageKpj'));
const HealthPackagesKpj = lazy(() =>
    import('./pages/HealthPackagesKpj/HealthPackagesKpj')
);
const MyCollectionsArticlesKpj = lazy(() =>
    import('./pages/MyCollectionsArticlesKpj/MyCollectionsArticlesKpj')
);
const MyCollectionsPodcastKpj = lazy(() =>
    import('./pages/MyCollectionsPodcastKpj/MyCollectionsPodcastKpj')
);
const MyCollectionsVideosKpj = lazy(() =>
    import('./pages/MyCollectionsVideosKpj/MyCollectionsVideosKpj')
);
const HealthTourismKPJ = lazy(() => import('./pages/HealthTourismKPJ/HealthTourismKPJ'));
const InternationalPatientCentreKPJ = lazy(() =>
    import('./pages/InternationalPatientCentreKPJ/InternationalPatientCentreKPJ')
);
const CaresKPJ = lazy(() => import('./pages/CaresKPJ/CaresKPJ'));
const CampaignsPromotions = lazy(() =>
    import('./pages/CampaignsPromotions/CampaignsPromotions')
);
const IndividualCampaignsPromotions = lazy(() =>
    import('./pages/IndividualCampaignsPromotions')
);
const CaresKPJApp = lazy(() => import('./pages/CaresKPJApp/CaresKPJApp'));
const Video = lazy(() => import('./pages/Video/Video'));
const IndividualSpecialistKpj = lazy(() =>
    import('./pages/EyeSpecialistKPJ/IndividualSpecialistKpj')
);
const InsightsCornerKPJ = lazy(() =>
    import('./pages/InsightsCornerKPJ/InsightsCornerKPJ')
);
const ContactUsKPJ = lazy(() => import('./pages/ContactUsKPJ/ContactUsKPJ'));
const IndividualVideo = lazy(() => import('./pages/IndividualVideo'));
const Podcast = lazy(() => import('./pages/Podcast'));
const IndividualPodcast = lazy(() => import('./pages/IndividualPodcast'));
const Articles = lazy(() => import('./pages/Articles'));
const IndividualArticle = lazy(() => import('./pages/IndividualArticle'));
const Events = lazy(() => import('./pages/Events'));
const IndividualEvent = lazy(() => import('./pages/IndividualEvent'));
const SymtonCheck = lazy(() => import('./pages/SymtonCheck'));
const FrequentlyAskedQuestionsKPJ = lazy(() =>
    import('./pages/FrequentlyAskedQuestionsKPJ/FrequentlyAskedQuestionsKPJ')
);
const TeleconsultKPJ = lazy(() => import('./pages/TeleconsultKPJ/TeleconsultKPJ'));
const MyCartKPJ = lazy(() => import('./pages/MyCartKPJ/MyCartKPJ'));
const AboutKPJ = lazy(() => import('./pages/AboutKPJ/AboutKPJ'));
const SettingKPJ = lazy(() => import('./pages/SettingKPJ/SettingKPJ'));
const HomeHeader = lazy(() => import('./pages/Home/Home/HomeHeader/HomeHeader'));
const HomeKpjFooter = lazy(() => import('./pages/Home/Home/HomeKpjFooter/HomeKpjFooter'));
const MyPurchasesKPJ = lazy(() => import('./pages/MyPurchasesKPJ/MyPurchasesKPJ'));
const SearchKPJ = lazy(() => import('./pages/SearchKPJ/SearchKPJ'));
const ServiceKPJ = lazy(() => import('./pages/ServiceKPJ/ServiceKPJ'));
const HistoryKPJ = lazy(() => import('./pages/HistoryKPJ/HistoryKPJ'));
const PatientCareKPJ = lazy(() => import('./pages/PatientCareKPJ/PatientCareKPJ'));
const IndividualSpecialistService = lazy(() =>
    import('./pages/IndividualSpecialistService/IndividualSpecialistService')
);
const Receipt = lazy(() => import('./pages/MyPurchasesKPJ/Receipt'));
const Tender = lazy(() => import('./pages/Tender/Tender'));
const Career = lazy(() => import('./pages/Career/Career'));
const News = lazy(() => import('./pages/News/News'));
const MembershipTierRanking = lazy(() =>
    import('./pages/CaresKPJ/MembershipTierRanking')
);
const CaresTermsAndConditions = lazy(() => import('./pages/CaresKPJ/TermsAndCondition'));
const CaresFAQ = lazy(() => import('./pages/CaresKPJ/FAQ'));
const CaresPlatinumPlus = lazy(() => import('./pages/CaresKPJ/PlatinumPlus'));
const IndividualMedicalService = lazy(() =>
    import('./pages/IndividualHospitalKpj/IndividualMedicalService')
);

const Consultant = lazy(() => import('./pages/Consultant'));

const GlApplication = lazy(() => import('./pages/GlApplication'));
const AmbulatoryCareAndWellness = lazy(() => import('./pages/AmbulatoryCareAndWellness'));
const KlinikWaqaf = lazy(() => import('./pages/KlinikWaqaf'));

const AnciliaryServices = lazy(() => import('./pages/AnciliaryServices'));

const AppRoutes = () => {
    const [seo_info, setSeoInfo] = useState(null);
    const [_token, setToken] = useState(getToken());

    const navigate = useNavigate();
    const location = useLocation();

    // for seo url usage
    const componentMap = {
        doctor: IndividualDoctorKpj, // checked on 24/11/2023, works
        hospital: IndividualHospitalKpj, // checked on 24/11/2023, works
        'speciality-centre': IndividualSpecialistKpj, // checked on 24/11/2023, works
        'speciality-centre-service': IndividualSpecialistService, // checked on 24/11/2023, works
        'center-of-excellence': IndividualMedicalService, // checked on 24/11/2023, works
        'health-package': IndividualHealthPackageKpj, // checked on 24/11/2023, works
        events: IndividualEvent, // checked on 24/11/2023, works
        'campaigns-promotions': IndividualCampaignsPromotions, // checked on 24/11/2023, works
        articles: IndividualArticle, // checked on 24/11/2023, works
        video: IndividualVideo, // checked on 24/11/2023, works
        podcast: IndividualPodcast, // checked on 24/11/2023, works
    };

    useEffect(() => {
        let isMounted = true;
        let token_recreate = _token || null;
        checkTokenValidity()
            .then((isValid) => {
                if (isMounted) {
                    if (!isValid) {
                        get_token('get_token')
                            .then((data) => {
                                localStorage.setItem('token', data.token);
                                setToken(data.token);
                                token_recreate = data.token;
                            })
                            .catch((error) => {
                                console.log('get_token API error:', error);
                            });
                    }
                }
            })
            .catch((error) => {
                console.log('Check token API error:', error);
            });

        if (_token) {
            let _type = 'keyword'; // keyword or module, keyword means seo url, module means /:module/:id
            if (location.pathname.split('/').length === 3) {
                _type = 'module';
            }
            api_seo(location.pathname, _token, _type)
                .then((response) => {
                    if (response.status) {
                        if (_type == 'module') {
                            navigate(response.data.keyword);
                        }
                        setSeoInfo({
                            url: response.data.url,
                            keyword: location.pathname,
                            component: response.data.component,
                            id: response.data.id,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // get favicon
            api_get_favicon()
                .then((res) => {
                    if (res.status) {
                        changeFavicon('icon', res?.data?.favicon);
                        changeFavicon('apple-touch-icon', res?.data?.favicon);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        return () => {
            isMounted = false;
            setSeoInfo(null);
        };
    }, [location.pathname, _token]);

    // function to change favicon
    const changeFavicon = (rel, src) => {
        var favicon = document.querySelector('link[rel="' + rel + '"]');
        if (favicon) {
            favicon.href = src;
        } else {
            // If the favicon element doesn't exist, create a new one
            var newFavicon = document.createElement('link');
            newFavicon.rel = rel;
            newFavicon.href = src;

            // Append the new favicon element to the head
            document.head.appendChild(newFavicon);
        }
        // trigger change by changing title
        const default_title = document.title;
        document.title = 'KPJ';
        document.title = default_title;
    };

    const checkTokenValidity = async () => {
        try {
            return get_token('check_token', _token)
                .then((data) => {
                    return data.status !== false;
                })
                .catch((error) => {
                    return false;
                });
        } catch (error) {
            return false;
        }
    };
    const DynamicComponent = componentMap[seo_info?.component];
    return seo_info && DynamicComponent ? (
        <Routes>
            <Route
                path={seo_info.keyword}
                element={<DynamicComponent _id={seo_info.id} _hosp={seo_info?.hosp} />}
            />
        </Routes>
    ) : (
        <Routes>
            <Route path='/home' element={<Home />} />

            {/* <Route
        path={seoPath}
        element={
          seoPage === "hospital" ? (
            <IndividualHospitalKpj id={individualId} />
          ) : seoPage === "doctor" ? (
            <IndividualDoctorKpj id={individualId}/>
          ) : (
            <Home />
          )
        }
      /> */}

            {/* hospital */}
            <Route path='/hospital' element={<OurHospitalKpj />} />
            <Route path='/hospital/:id' element={<IndividualHospitalKpj />} />
            <Route
                path='/hospital/medical-service/:id'
                element={<IndividualMedicalService />}
            />

            {/* doctors */}
            <Route path='/doctor' element={<FindADoctorsKpj />} />
            <Route path='/doctor/:id' element={<IndividualDoctorKpj />} />

            {/* health packages */}
            <Route path='/health-package' element={<HealthPackagesKpj />} />
            <Route path='/health-package/:id' element={<IndividualHealthPackageKpj />} />

            {/* profile */}
            <Route path='/profile' element={<ProfilePageKpj />} />
            <Route
                path='/my-collections-article'
                element={<MyCollectionsArticlesKpj />}
            />
            <Route path='/my-collections-podcast' element={<MyCollectionsPodcastKpj />} />
            <Route path='/my-collections-video' element={<MyCollectionsVideosKpj />} />
            <Route path='/history' element={<HistoryKPJ />} />
            <Route path='/purchases' element={<MyPurchasesKPJ />} />
            <Route path='/cart' element={<MyCartKPJ />} />
            <Route path='/setting' element={<SettingKPJ />} />

            {/* service */}
            <Route path='/speciality-centre' element={<OurSpecialityCentreKpj />} />
            <Route path='/speciality-centre/:id' element={<IndividualSpecialistKpj />} />
            <Route
                path='/speciality-centre-service/:id'
                element={<IndividualSpecialistService />}
            />

            {/* campro */}
            <Route path='/campaigns-promotions' element={<CampaignsPromotions />} />
            <Route
                path='/campaigns-promotions/:id'
                element={<IndividualCampaignsPromotions />}
            />

            {/* videos */}
            <Route path='/video' element={<Video />} />
            <Route path='/video/:id' element={<IndividualVideo />} />

            {/* podcast */}
            <Route path='/podcast' element={<Podcast />} />
            <Route path='/podcast/:id' element={<IndividualPodcast />} />

            {/* articles */}
            <Route path='/articles' element={<Articles />} />
            <Route path='/articles/:id' element={<IndividualArticle />} />

            {/* event */}
            <Route path='/events' element={<Events />} />
            <Route path='/events/:id' element={<IndividualEvent />} />

            {/* kpj cares */}
            <Route path='/cares' element={<CaresKPJ />} />
            <Route path='/cares-membership' element={<MembershipTierRanking />} />
            <Route path='/cares-platinum-plus' element={<CaresPlatinumPlus />} />
            <Route path='/cares-app' element={<CaresKPJApp />} />
            <Route path='/cares-terms' element={<CaresTermsAndConditions />} />
            <Route path='/cares-faq' element={<CaresFAQ />} />

            <Route path='/health-tourism' element={<HealthTourismKPJ />} />
            <Route path='/ipc' element={<InternationalPatientCentreKPJ />} />

            <Route path='/insights-corner' element={<InsightsCornerKPJ />} />
            <Route path='/contact-us' element={<ContactUsKPJ />} />

            <Route path='/faq' element={<FrequentlyAskedQuestionsKPJ />} />
            <Route path='/teleconsult' element={<TeleconsultKPJ />} />
            <Route path='/service/:id' element={<ServiceKPJ />} />
            <Route path='/patient-care/:id' element={<PatientCareKPJ />} />

            {/* tender */}
            <Route path='/tender' element={<Tender />} />

            {/* career */}
            <Route path='/career' element={<Career />} />

            {/* news */}
            <Route path='/news' element={<News />} />
            <Route path='*' element={<Home />} />
            <Route path='/' element={<Home />} />

            {/* below not implement react lazy load */}
            <Route path='/about' element={<AboutKPJ />} />
            <Route path='/search' element={<SearchKPJ />} />
            <Route path='/symptom-check' element={<SymtonCheck />} />
            <Route path='/contact' element={<Contact />} />

            {/* payment */}
            <Route path='/payment-success' element={<Success />} />
            <Route path='/payment-fail' element={<Fail />} />
            <Route path='/receipt/:id' element={<Receipt />} />

            <Route path='/not-match' element={<NotFound />} />

            <Route path='/consultant' element={<Consultant />} />

            <Route path='/gl-application' element={<GlApplication />} />

            <Route
                path='/ambulatory-care-and-wellness'
                element={<AmbulatoryCareAndWellness />}
            />

            <Route path='/klinik-wakaf-an-nur' element={<KlinikWaqaf />} />

            <Route path='/anciliary-services' element={<AnciliaryServices />} />
        </Routes>
    );
};

const App = () => {
    //console.log("localStorage", localStorage);
    const userIsAuthenticated = isAuthenticated();

    return (
        <Suspense
            fallback={
                <div className='loading-container'>
                    <CircularProgress />
                </div>
            }>
            <AllContext>
                <BrowserRouter>
                    <ScrollTop />
                    <CartProvider>
                        <AuthProvider>
                            <TabProvider>
                                <LangProvider>
                                    <HomeHeader />
                                    <DoctorFilterProvider>
                                        <HealthPackageProvider>
                                            {/* <HelmetMetaData></HelmetMetaData> */}
                                            {/* {userIsAuthenticated && <AppRoutes />} */}
                                            <AppRoutes />
                                        </HealthPackageProvider>
                                    </DoctorFilterProvider>
                                    <HomeKpjFooter />
                                </LangProvider>
                            </TabProvider>
                        </AuthProvider>
                    </CartProvider>
                </BrowserRouter>
            </AllContext>
        </Suspense>
    );
};

const isAuthenticated = () => {
    const userId = localStorage.getItem('user_id');
    return userId !== null;
};

const PrivateRoute = ({ children }) => {
    const userIsAuthenticated = isAuthenticated();

    if (userIsAuthenticated) {
        return children;
    }

    return <Link to='/login' />;
};

export default App;

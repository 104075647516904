import Swal from "sweetalert2";
import axios from "axios";
import {
  post_login,
  api_token,
  post_register,
  post_otp,
  reset_password,
  forgot_password_phone,
} from "../apiConfig";
import { getToken, getPatientInfo } from "../../localstorage";

const get_token = async (action, token) => {
  const requestData = {
    action: action,
    token: token,
    patientId: getPatientInfo()?.id ?? null,
  };
  return axios
    .post(api_token, requestData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const handle_login = (
  email,
  password,
  setIsLoggedIn,
  setIsGuest,
  setPatientId,
  setLoggedInToken
) => {
  const postData = {
    email: email,
    password: password,
  };

  axios
    .post(post_login, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      if (response.data.status) {
        setIsLoggedIn(true);
        setIsGuest(false);
        setLoggedInToken(response.data.token);

        //console.log(response.data.token);

        localStorage.setItem("firstname", response.data.account.firstname);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("patient_id", response.data.account.user_id);
        localStorage.setItem("patient_nric", response.data.account.nric);

        setPatientId(response.data.account.user_id);

        // Delay the page reload for 1 second (adjust the delay as needed)
        setTimeout(() => {
          window.location.reload();
        }, 2000);

        //window.location.reload();
        //Swal.fire({
        //  icon: "success",
        //  title: "Login",
        //  text: "Login successfully!",
        //}).then(() => {
        //  window.location.reload();
        //});
      } else {
        Swal.fire({
          icon: "error",
          title: "Login",
          text: "Invalid email or password. Please try again.",
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Login",
        text: error,
      });
    });
};

const api_register = async (postData) => {
  return axios
    .post(post_register, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

  //try {
  //  const response = await axios.post(post_register, postData, {
  //    headers: {
  //      "Content-Type": "application/json",
  //      "X-API-KEY": "kpjhealth",
  //      Authorization: `Bearer ${getToken()}`,
  //    },
  //  });
  //  return response;
  //} catch (error) {
  //  throw error;
  //}
};

const api_otp = async (otp_code, phone) => {
  const postData = {
    otp_code: otp_code,
    phone_no: phone,
  };

  return axios
    .post(post_otp, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  //try {
  //  const response = await axios
  //    .post(post_otp, {
  //      headers: {
  //        "Content-Type": "application/json",
  //        "X-API-KEY": "kpjhealth",
  //        "Authorization": `Bearer ${getToken()}`,
  //      },
  //    });
  //  return response;
  //} catch (error) {
  //  throw error;
  //}
};

const resetPassword = async (postData) => {
  return axios
    .post(reset_password, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const forgotPasswordPhone = async (postData) => {
  return axios
    .post(forgot_password_phone, postData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export {
  handle_login,
  get_token,
  api_register,
  api_otp,
  resetPassword,
  forgotPasswordPhone,
};

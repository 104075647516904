import RAZER_CONSTANTS from "../constants/razer";
import { MD5 } from "crypto-js";
import axios from "axios";

// generate payment url
export const getPaymentURL = ({amount, orderID, name, email, contact, desc} = {}) => {
  // if(!amount || !orderID || !name || !contact || !desc){
  // 23/10/2023, for non logged in user 
  if(!amount || !orderID || !desc){
    return {
      status: false,
      reason: 'Invalid data, please double confirm'
    }
  }
  const country = 'MY';
  const vcode = genVerifyCode(amount, orderID);
  if(!vcode.status){
    return vcode;
  }
  const params = [
    `amount=${encodeURIComponent(amount)}`,
    `orderid=${encodeURIComponent(orderID)}`,
    `bill_name=${encodeURIComponent(name)}`,
    `bill_email=${encodeURIComponent(email)}`,
    `bill_mobile=${encodeURIComponent(contact)}`,
    `bill_desc=${encodeURIComponent(desc)}`,
    `country=${encodeURIComponent(country)}`,
    `vcode=${encodeURIComponent(vcode.data)}`,
  ];

  const paymentURL = `${RAZER_CONSTANTS.PAYMENT_URL}/${RAZER_CONSTANTS.MERCHANT_ID}?${params.join('&')}`;
  if(paymentURL){
    return {
      status: true,
      message: 'Get Payment URL',
      data: paymentURL
    }
  }
  else{
    return {
      status: false,
      message: 'Failed to get Payment URL',
    }
  }
}

export const notify = async (postData) => {
  try {
    const response = await axios.post(RAZER_CONSTANTS.RETURN_IPN_URL, postData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    console.log({response});

    // Access the response data and HTTP status code.
    const resData = response.data;
    const httpCode = response.status;

    // Perform the _verify_skey() function or further processing here.
    const status = verifySecretKey(resData);
    if (status) {
      // Success
    } else {
      // Failed
    }
  } catch (error) {
    // Handle any errors that occur during the request.
    console.error('Error making the payment request:', error);
  }
}

// verify secret key based on postData(returned from razer callback)
export const verifySecretKey = (postData) => {
  const key0 = MD5(postData?.tranID.toString() + postData?.orderid + postData?.status + postData?.domain + postData?.amount + postData?.currency);
  const key1 = MD5(postData?.paydate.toString() + postData?.domain + key0 + postData?.appcode + RAZER_CONSTANTS.SECRET);

  if(postData?.skey === key1){
    // success
    return true;
  }
  return false;
}

// generate verify code
const genVerifyCode = (amount, orderID) => {
  if(!amount){
    return {
      status: false,
      reason: 'Invalid Amount'
    };
  }
  if(!orderID){
    return {
      status: false,
      reason: 'Invalid orderID'
    };
  }
  const vcode = MD5(amount.toString() + RAZER_CONSTANTS.MERCHANT_ID + orderID + RAZER_CONSTANTS.VERIFY);
  if(vcode){
    return {
      status: true,
      message: 'Verify code generated',
      data: vcode
    };
  }
  else{
    return {
      status: false,
      reason: 'Verify code failed to generate'
    }
  }
}

import axios from "axios";
import { get_account, edit_account, edit_notification } from "../../apiConfig";
import { getToken } from "../../../localstorage";

//get account
const api_get_account = async () => {
  return axios
    .get(get_account, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
//edit account
const api_edit_account = async (formData) => {
  return axios
    .post(edit_account, formData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

//edit notification
const api_edit_notification = async (formData) => {
  return axios
    .post(edit_notification, formData, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export { api_get_account, api_edit_account, api_edit_notification };

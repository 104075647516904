import axios from "axios";
import {
  get_hospita_detail,
  get_hospital_coe,
  get_hospital_individual_detail,
  get_hospital_listing,
  get_hospital_service,
  get_hospital_patient_care,
  get_favicon
} from "../apiConfig";
import { getToken } from "../../localstorage";

const api_get_hospital_details = async (lang, limit, offset, filter = {}) => {
  const params = {
    limit: limit,
    offset: offset,
    lang: lang,
    ...filter,
  };

  try {
    const response = await axios.get(get_hospita_detail, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
      params: params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const api_get_hospital_listings = async (
  lang,
  limit,
  offset,
  location,
  filter = {}
) => {
  const params = {
    limit: limit,
    offset: offset,
    lang: lang,
    location: location,
    ...filter,
  };

  try {
    const res = await axios.get(get_hospital_listing, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
      params: params,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

const api_get_individual_hospital = async (lang, id) => {
  if (!id) {
    return {
      data: {},
    };
  }
  try {
    const res = await axios.get(get_hospital_individual_detail, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        lang: lang,
        id: id,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

const api_get_hospital_service = async (lang, id) => {
  if (!id) {
    return {
      data: {},
    };
  }
  try {
    const res = await axios.get(get_hospital_service, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        lang: lang,
        id: id,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

const api_get_hospital_coe = async (id) => {
  try {
    const res = await axios.get(get_hospital_coe, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        id: id,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

const api_get_hospital_patient_care = async (id) => {
  try {
    const res = await axios.get(get_hospital_patient_care, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        id: id,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

// get favicon
const api_get_favicon = async (id = null) => {
  try {
    const res = await axios.get(get_favicon, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        "Authorization": `Bearer ${getToken()}`,
      },
      params: {
        hospital_id: id,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export {
  api_get_hospital_details,
  api_get_hospital_listings,
  api_get_individual_hospital,
  api_get_hospital_service,
  api_get_hospital_coe,
  api_get_hospital_patient_care,
  api_get_favicon
};
